<template>
    <div style="width: 100%;">
        <div class="introduction-title">托管系统介绍</div>
        <div class="introduction-bg">
            <div class="introduction-body">
                <img class="intro-banner" @click="goTuoGuan()" src="@/assets/image/patent/trust/intro-banner.png"
                     alt="">
                <div class="intro-content">
                    <div class="title">系统简介</div>
                    <div class="content">专利托管系统可为企业、高校、政府、园区、专家、机构提供集</div>
                    <div class="content" style="color:#377EF7;">①检索查询—②专利监控—③预警通知—④在线缴费—⑥知产服务</div>
                    <div class="content">为一体的信息化工具与载体，助力创新主体的知识产权管理提升效率，</div>
                    <div class="content">节省成本、降低风险、了解竞争对手、跟踪技术趋势。</div>
                </div>
            </div>
        </div>
        <div class="core-title">核心功能</div>
        <div class="core-body">
            <div class="core-item" v-for="(item, index) in coreList" :key="index">
                <img :src="item.src" class="core-img" alt="">
                <div style="margin-left: 14px">
                    <div class="core-item-title">{{item.title}}</div>
                    <div class="core-item-content">{{item.content}}</div>
                </div>
            </div>
        </div>
        <div class="advantage-title">特色优势</div>
        <div class="advantage-body">
            <div class="advantage-body-top-left"></div>
            <span class="sea-wave"></span>
            <span class="sea-wave"></span>
            <span class="sea-wave"></span>
            <div class="advantage-info">
                <div class="left-img-list">
                    <img src="@/assets/image/patent/trust/efficency.png" alt="">
                    <img style="margin: 40px 0" src="@/assets/image/patent/trust/cost.png" alt="">
                    <img src="@/assets/image/patent/trust/risk.png" alt="">
                </div>
                <div class="left-img-list-info">
                    <div style="margin-top: 15px">提升效率</div>
                    <div>在线缴费5分钟，线下折腾5小时</div>
                    <div style="margin-top: 61px">节省成本</div>
                    <div>传统机构50-100/件，线上代缴仅需5分之一</div>
                    <div style="margin-top: 78px">降低风险</div>
                    <div>滞纳金、未缴费失效，知产损失从说拜拜</div>
                </div>
                <img src="@/assets/image/patent/trust/case.png" style="width: 70px;height: 174px;" alt="">
                <div class="text-vertical">成功案例</div>
                <div class="advantage-content">江苏某高校，年维持专利数量在5000件左右，学校科技处之前专门安排一个人员每天打电话给各个老师，
                    确定是否需要缴纳年费，然后线下委托服务机构以100/件的价格委托代缴费，并做好纸质文档记录，费时费力，
                    还经常因为遗忘造成产生滞纳金。使用中高托管系统后，全年再没有出现滞纳金情况，工作量、
                    工作时间至少也降低了90%，直接缴费成本更是缩减了70-80%。
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "PatentTrusteeshipIndex",
        computed: {
            ...mapGetters({
                token: 'userStore/token',
                userName: 'userStore/userName',
                userId: 'userStore/userId',
                enterpriseState: 'userStore/enterpriseState'
            }),
        },
        data() {
            return {
                coreList: [
                    {
                        title: '警告预警',
                        content: '可选择微信、短信、邮件主动通知费用、状态信息，避免传统模式的遗忘；',
                        src: require('@/assets/image/patent/trust/warning.png')
                    },
                    {
                        title: '在线缴费',
                        content: '在线支付，提供官方票据自2021年1月1 日起专利缴费提供国知局电子票据；；',
                        src: require('@/assets/image/patent/trust/free.png')
                    },
                    {
                        title: '专利检索',
                        content: '支持全国2400万全量专利数据的检索、浏览、以及PDF文档的下载等；',
                        src: require('@/assets/image/patent/trust/trust.png')
                    },
                    {
                        title: '知产服务',
                        content: '提供专利、商标、版权、法律、资质、评估分析，等一站式知识产权延伸服务；',
                        src: require('@/assets/image/patent/trust/service.png')
                    },
                    {
                        title: '年费监控',
                        content: '自动同步国知局专利年费信息，清晰显示费用明细，维持成本明明白白；',
                        src: require('@/assets/image/patent/trust/monitor-fee.png')
                    },
                    {
                        title: '状态监控',
                        content: '专利审查与维持过程中60多个法律状态的实时监控，随时了解业务进程；',
                        src: require('@/assets/image/patent/trust/monitor-legal.png')
                    }
                ]

            }
        },
        methods: {
            goTuoGuan() {
                if (this.enterpriseState !== 'CERTIFIED') {
                    this.$router.push({
                        path: '/enterprise/EnterpriseCertification'
                    })
                } else {
                    this.$router.push({
                        path: '/enterprise/patent-trusteeship?id=0'
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    .introduction-title {
        font-size: 30px;
        font-weight: 400;
        color: #333333;
        line-height: 73px;
        text-align: center;
        margin-top: 50px;
    }

    .introduction-bg {
        background-image: url("~@/assets/image/patent/trust/intro-bg.png");
        background-size: 100% 100%;
        width: 1244px;
        height: 372px;
        margin: 0 auto;
        position: relative;
    }

    .introduction-body {
        position: absolute;
        top: 11px;
        left: 22px;
        width: 1192px;
        height: 330px;
        background: #fff;
        border-radius: 8px;
        display: flex;

        .intro-banner {
            width: 612px;
            height: 330px;
            cursor: pointer;
        }

        .intro-content {
            width: 580px;
            text-align: center;

            .content {
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                line-height: 41px;
                text-align: center;
            }
        }

        .title {
            height: 24px;
            font-size: 24px;
            font-weight: bold;
            color: #333333;
            line-height: 41px;
            margin: 70px 0 28px 0;
        }
    }

    .core-title {
        font-size: 30px;
        font-weight: 400;
        color: #333333;
        line-height: 73px;
        text-align: center;
    }

    .core-body {
        width: 1242px;
        height: 330px;
        display: grid;
        grid-template-columns: repeat(3, 380px);
        grid-template-rows: repeat(2, 151px);
        justify-content: space-around;
        align-content: space-between;
        margin: 0 auto;

        .core-item {
            width: 380px;
            height: 151px;
            background: #FFFFFF;
            border-radius: 10px;
            box-shadow: 0 6px 20px -6px rgba(0, 0, 0, .2);
            display: flex;
            align-items: center;
            padding: 20px;

            .core-img {
                width: 75px;
                height: 75px;
            }

            .core-item-title {
                font-size: 18px;
                font-weight: 400;
                color: #333333;
                margin-top: 5px;
            }

            .core-item-content {
                font-size: 14px;
                font-weight: 400;
                color: #7984A4;
                line-height: 24px;
                margin-top: 20px;
            }
        }
    }

    .advantage-title {
        font-size: 30px;
        font-weight: 400;
        color: #333333;
        line-height: 73px;
        text-align: center;
        margin-top: 50px;
    }

    .advantage-body {
        width: 1200px;
        height: 415px;
        background: #FFFFFF;
        border-radius: 10px;
        margin: 30px auto 50px;
        box-shadow: 0 6px 20px -6px rgba(0, 0, 0, .2);
        position: relative;
        overflow: hidden;
    }

    .advantage-body-top-left {
        width: 100px;
        height: 100px;
        background: #39A2F7;
        opacity: 0.15;
        border-bottom-right-radius: 70px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .sea-wave {
        position: absolute;
        bottom: -200px;
        right: -116px;
        width: 300px;
        height: 250px;
        background: #39A2F7;
        border-radius: 43%;
        filter: opacity(0.43);
        animation: drift linear infinite;
        transform-origin: 50% 48%;
    }

    .sea-wave:nth-of-type(1) {
        animation-duration: 5s;
    }

    .sea-wave:nth-of-type(2) {
        animation-duration: 7s;
    }

    .sea-wave:nth-of-type(3) {
        animation-duration: 9s;
        background-color: #39A2F7;
        filter: opacity(0.15);
    }

    @keyframes drift {
        from {
            transform: rotate(360deg);
        }
    }

    .advantage-info {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .left-img-list {
        height: 315px;
        width: 78px;
        position: relative;
        img{
            width: 78px;
            height: 78px;
            border-radius: 50%;
            background-color: #fff;
            z-index: 2;
            position: relative;
        }
    }
    .left-img-list:before {
        content: '';
        position: absolute;
        height: 300px;
        width: 1px;
        border-right: 2px dashed #C3E2FE;
        top: 7px;
        left: 39px;
    }

    .left-img-list-info {
        width: 313px;
        height: 315px;
        padding-left: 27px;
        div:nth-child(2n+1){
            font-size: 18px;
            color: #333333;
            height: 20px;
            line-height: 20px;
        }
        div:nth-child(2n){
            font-size: 14px;
            color: #7984A4;
            height: 14px;
            line-height: 14px;
            margin-top: 20px;
        }
    }

    .text-vertical {
        writing-mode: vertical-lr;
        width: 90px;
        font-size: 24px;
        font-weight: bold;
        line-height: 90px;
    }

    .advantage-content {
        width: 450px;
        height: 196px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        text-indent: 2em;
    }
</style>
